


const user = {
  state: {
    columnList:'',
  },

  mutations: {
    SET_COLUMNLIST: (state, columnList) => {
      state.columnList = columnList
    },





  },
  actions:{
        SetColumnList({commit},columnList){
            return new Promise((resolve, reject) => {
            commit('SET_COLUMNLIST', columnList)
            resolve('ok')
    
            })
        },
  }
}

export default user