<template>
  
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>

    
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <!-- <Foot /> -->
    <!-- <Search /> -->
  </div>
</template>



<script>
import Foot from "@/components/foot.vue"
import Search from "@/components/search.vue"


export default {
  components:{
    Foot,
    Search,
  },
  data(){
    return {

    }
  },
  mounted(){

  },
  computed:{
	    key(){
	        return Math.random();
	    }
	}
}
</script>

<style lang="scss" scoped>
#app{
  width: 100%;
  height: 100%;
}
</style>