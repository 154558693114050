import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'


Vue.config.productionTip = false

import "@/assets/styles/index.scss";


import Swiper from 'swiper'
import 'swiper/dist/css/swiper.min.css'


import $ from 'jquery'

import Cookies from 'js-cookie'

import Element from "element-ui"
import 'element-ui/lib/theme-chalk/index.css';
//Vue.use(Element)  // 全局注册声明 element

import {axios} from "@/utils/axios"
Vue.prototype.$axios = axios;  // 配置全局 Vue的原型属性

Vue.use(Element, {
  size: Cookies.get('size') || 'small' // set element-ui default size
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

