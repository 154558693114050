<template>

    <div class="searchbox close-searchbox">


        <div class="modal-dialog close-modal-dialog animated bounceIn" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" @click="closeSearch">
                        <span>×</span>
                    </button>
                    <h4 class="modal-title" id="myModalLabel">
                        登录</h4>
                </div>
                <div class="modal-body">
                    <div class="input-group">
                        <!-- <span>手机号</span> -->
                        <input id="k" placeholder="请填入您的手机号码" v-model="phone">
                    </div>
                    <div class="input-group">
                        <!-- <span>验证码</span> -->
                        <input id="p" placeholder="请输入验证码" v-model="code">
                        <div class="sendcode" @click="sendcode">发送验证码</div>
                    </div>
                    <!-- <div class="input-group">
                        <span>您的姓名</span><input id="n" placeholder="请输入您的姓名" v-model="name">
                    </div> -->
                    <span class="btn" @click="doSubmit()">登录</span>
                </div>
                <!-- <div class="modal-footer">
                    <span>温馨提示：鉴别顾问将在第一时间反馈鉴别结果，<br />
                        请确保您的信息真实有效！</span>
                </div> -->
            </div>
        </div>


    </div>


    
</template>


<script>
import Cookies from 'js-cookie'
import {saveOrUpdate,sendCode,loginBySms} from "@/api/index"
import { Loading ,Message } from 'element-ui';
export default {
    data(){
        return {
            flag:false,
            phone:"",
            code:"",
            load : null,
            yzmt:null,
            yzms:60,
            yzmflag:true,
            ywltoken:null,
        }
    },
    methods:{
        closeSearch(){
            document.getElementsByClassName("modal-dialog")[0].classList.add("close-modal-dialog")
            document.getElementsByClassName("searchbox")[0].classList.add("close-searchbox")
            document.body.style.overflow='visible'
            // if(!this.flag){
            //     setTimeout(()=>{
            //         document.getElementsByClassName("modal-dialog")[0].classList.remove("close-modal-dialog")
            //         document.getElementsByClassName("searchbox")[0].classList.remove("close-searchbox")
            //         document.body.style.overflow='hidden'
            //     },10000)
            // }
        },
        doSubmit(){
            if (this.phone == "" || this.phone.replace(" ", "") == "") {
                alert("请填写您的手机号");
                return;
            }
            if (!(/^1[23456789]\d{9}$/.test(this.phone))) {
                alert("请输入有效的手机号码");
                return;
            }
            if (this.code == "" || this.code.replace(" ", "") == "") {
                alert("请填写验证码");
                return;
            }

            this.showLoading()

            loginBySms({
                "phoneNum": this.phone,
                "smsCode": this.code,
                "source": "引未来官网"
            }).then(res => {
                console.log(res.token)
                this.load && this.load.close();
                Message.success({
                    message: "登录成功",
                    center: true,
                    duration:1200,
                    showClose: true,
                })
                Cookies.set('ywltoken', res.token, { expires: 7 })
                this.ywltoken = res.token;

            }).catch(res => {
                console.log(res.message)
            })

            // saveOrUpdate({
            //     "id":0,
            //     "customerName": this.name,
            //     "customerPhone": this.phone,
            //     "customerRemarks": this.search,
            //     "sourceTypeId":1
            // }).then(res => {
            //     alert("我们已收到您查询的信息，将会尽快联系您。")
            //     this.phone = "";
            //     this.name = "";
            //     this.search = "";
            //     this.flag = true;
            //     this.load && this.load.close();
            //     document.getElementsByClassName("modal-dialog")[0].classList.add("close-modal-dialog")
            //     document.getElementsByClassName("searchbox")[0].classList.add("close-searchbox")
            //     document.body.style.overflow='visible'
            // })




        },
        showLoading(){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: '登录中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        sendcode(){
            var yzmbtn = document.getElementsByClassName("sendcode")[0];
            if (!this.phone) {
                alert("请填写您的手机号");
                return;
            }
            if (!(/^1[23456789]\d{9}$/.test(this.phone))) {
                alert("输入的手机号码不正确，请重新输入");
                return;
            }


            if(this.yzmflag){
                this.yzms = 60;
                this.yzmflag = false;

                sendCode({
                    "phoneNum": this.phone
                }).then(res => {
                    console.log(res)
                })


                this.yzmt = setInterval(() => {
                    //console.log(this.yzms)
                    
                    this.yzms--;
                    yzmbtn.innerHTML = this.yzms + "s";

                    if(this.yzms < 0){
                        clearInterval(this.yzmt)
                        this.yzmt = null;
                        yzmbtn.innerHTML = "获取验证码";
                        this.yzmflag = true;
                    }
                    
                    
                }, 1000);
            }else{

            }
        },
    },
    mounted() {

        this.ywltoken =  Cookies.get('ywltoken') || null;
        console.log(this.ywltoken)

        
        if(!this.ywltoken){
            setTimeout(()=>{
                document.getElementsByClassName("modal-dialog")[0].classList.remove("close-modal-dialog")
                document.getElementsByClassName("searchbox")[0].classList.remove("close-searchbox")
                document.body.style.overflow='hidden'
            },5000)
        }
        
    }
}
</script>


<style lang="scss">

.close-searchbox{
    display: none;
}
.searchbox{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 999;
    background-color: rgba(0,0,0,.5);
    transition: 1s ease;
    .close-modal-dialog{
        display: none;
    }

    .modal-dialog{
        position: fixed;
        z-index: 1000;
        top: 25%;
        left: 3%;
        width: 7.1rem;
        height: 7.5rem;
        overflow: hidden;
        font-size: 0.28rem;
        transition: 1s ease;
        .modal-content{
            position: relative;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid rgba(0,0,0,.2);
            border-radius: 6px;
            box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
            outline: 0;
            .modal-header{
                background-color: #E20309;
                font-weight: 700;
                color: #fff;
                padding: 15px;
                border-bottom: 1px solid #e5e5e5;
                box-sizing: border-box;
                text-align: center;
                border-radius: 6px 6px 0 0;
                font-size: 0.34rem;
                .close{
                    margin-top: -2px;
                    padding: 0;
                    cursor: pointer;
                    background: 0 0;
                    border: 0;
                    appearance: none;
                    float: right;
                    font-size: 21px;
                    font-weight: 700;
                    line-height: 1;
                    color: #000;
                    text-shadow: 0 1px 0 #fff;
                    opacity: .2;
                }
            }
            .modal-body{
                position: relative;
                padding: 0.3rem;
                box-sizing: border-box;
                text-align: center;
                .input-group{
                    width: 100%;
                    margin-bottom: 0.4rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    border-collapse: separate;
                    span{
                        color: #E20309;
                        padding-right: 20px;
                        font-size: 0.32rem;
                    }
                    input{
                        padding: 5px 10px;
                        border: 1px solid #efefef;
                        outline: none;
                        width: 4.8rem;
                        height: 0.4rem;
                        font-size: 0.28rem;
                    }
                    #p{
                        width: 2rem;
                    }
                    .sendcode{
                        width: 2.8rem;
                        height: 0.64rem;
                        font-size: 0.32rem;
                        line-height: 0.64rem;
                        text-align: center;
                        color: #E20309;
                    }
                }
                .btn{
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                    padding: 0.15rem 0.6rem;
                    font-size: 0.32rem;
                    background-color: #E20309;
                    display: inline-block;
                    color: #fff;
                    border-radius: 0;
                    font-weight: 500;
                    touch-action: manipulation;
                    cursor: pointer;
                    background-image: none;
                    border: 1px solid transparent;
                    white-space: nowrap;
                    vertical-align: middle;
                    margin-bottom: 0;
                    user-select: none;
                    border-radius: 0.08rem;
                }
            }
            .modal-footer{
                padding: 0.3rem;
                border-top: 1px solid #e5e5e5;
                box-sizing: border-box;
                text-align: center;
            }
        }
    }
}






</style>