import axios from "axios";
import { Loading ,Message } from 'element-ui';
import router from "@/router"
import Cookies from 'js-cookie'


//let baseURL = "http://192.168.10.192:10522/"
//let baseURL = "http://43.138.19.35:10522/"
let baseURL = "https://request.beixiwenhua.com/"


axios.defaults.baseURL =   baseURL  // 请求的基路径  
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
//axios.defaults.headers.post['Content-Type'] = 'text/plain';

let load = null;


export function  showLoading(){
    load && load.close() ; 
    load = Loading.service({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    });
}

export function showSuccess(msg){
    msg = msg || "请求成功";
    load && load.close() ; 
    Message.success({
        message: msg,
        center: true,
        duration:1200,
        showClose: true,
    })
}

export function showError(msg){
    msg = msg || "加载失败，请刷新"
    load && load.close() ; 
    Message.error({
        message: msg,
        center: true,
        showClose: true,
        duration:1200
    })
}


// // Interceptors 拦截器 
// //  请求发送之前的拦截器 
// axios.interceptors.request.use(function (config) {
// // 请求发送之前做的事情 
//     if(config.method === 'get'){
//         console.log('get')
//         config.data = true;
//         config.headers['Accept'] = '*/*'

//         config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
//     }
    
//     // var token = sessionStorage.getItem("token");
//     // if(token){
//     //     config.headers['token'] = token;
//     // }
//     //showLoading()
//     return config;
// }, function (error) {
// // 请求发送失败 
//     showError('请求发送失败');
//     return Promise.reject(error);
// });


axios.interceptors.request.use(function (config) {
// 请求发送之前做的事情 
    
    if(Cookies.get('wjsToken')){
        config.headers['X-Access-Token'] = Cookies.get('wjsToken');
    }
    //showLoading()
    return config;
}, function (error) {
// 请求发送失败 
    showError('网络错误，请重试');
    return Promise.reject(error);
});




// 响应结束之前的拦截器 
axios.interceptors.response.use(function (response) {
    // 状态码判断 
    // 打印 返回的数据 
    console.log(response)  // axios 的数据存在data 
    // setTimeout(()=>{
    //     if(response.data.type){
    //         showSuccess(response.data.msg);
    //     }else{
    //         if(response.data.type==0){
    //             showError(response.data.msg);
    //         }else{
    //             showSuccess(response.data.msg);
    //         }
    //     }
    // },500)

    // if(response.code&&response.code=="401"){
    //     // token 有问题  重新登录
    //     showError('登录信息失效，请重新登录!');
    //     router.push({name:"dl"})
    // }

    return response;
  }, function (error) {
    // 响应失败 
    if (error.response) {
        console.log(error.response)
        if(error.response.data.message.includes("Token失效")){
            //console.log(error.response.data.message)

            showError('很抱歉，登录已过期，请重新登录');
            Cookies.remove('wjsToken')
            Cookies.remove('wjsUserInfo')
            setTimeout(() => {
                router.push({name:"dl"})
            },1500)
        }

    }else{
        showError('响应失败')

    }
    return Promise.reject(error);
});


// axios.interceptors.response.use(function (response) {
//     // 状态码判断 
//     // 打印 返回的数据 
//     //console.log(response.data)  // axios 的数据存在data 
//     setTimeout(()=>{
//         if(response.data){
//             load && load.close() ;
//         }else{
//             showError();
//         }
//     },500)

//     // if(response.data.code=="3200"){
//     //     // token 有问题  重新登录
//     //     router.push({name:"login"})
//     // }

//     return response;
//   }, function (error) {
//     // 响应失败 
//     showError('加载失败，请刷新')
//     return Promise.reject(error);
// });





export const get =(url,params)=>{
    return new Promise(function(resolve,reject){
        axios.get(url,{params})
        .then(res=>{
            resolve(res.data)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const post = (url,params)=>{
    return new Promise(function(resolve,reject){
        axios.post(url,params)
        .then(res=>{
            resolve(res.data)   // res.data  
        })
        .catch(err=>{
            reject(err);
        })
    })
}

export { axios,baseURL }; 

