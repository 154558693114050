<template>
    <div class="footer">
        <!-- <div class="footitem" @click="toindex"> <span>首页</span> </div>
        <div class="footitem" @click="toaboutus"> <span>关于我们</span> </div>
        <div class="footitem" @click="tomovielist"> <span>影视项目</span> </div>
        <div class="footitem" @click="tonews"> <span>新闻资讯</span> <img src="../assets/img/footerjt.png" alt="" id="footerjt" v-on:click.stop="changejt"> </div>
        <div id="hiddenbox2">
            <div class="footitem footitem2" @click="togsdt"> <span>公司动态</span> </div>
            <div class="footitem footitem2" @click="toyszx"> <span>影片推荐</span> </div>
            <div class="footitem footitem2" @click="tohyfx"> <span>行业分析</span> </div>
            <div class="footitem footitem2" @click="tozcjd"> <span>政策解读</span> </div>
        </div>
        <div class="footitem lastitem" @click="tocu"> <span>联系我们</span> </div>
        <p class="footerbottom">Copyright © 2019-2022 武汉引未来文化传媒有限公司 版权所有</p> -->
        <p class="footerP" style="marginBottom:.3rem;">地址：武汉市武昌区中山路374号湖北美术学院内 </p>
        <p class="footerP">鄂ICP备16016530号-3 增值电信业务经营许可证：</p>
        <p class="footerP">鄂B2-20180097</p>
        <p class="footerP" style="marginBottom:.3rem;">鄂公网安备 42010602001223号</p>
        <p class="footerP">Copyright @ 2012-2022 All Rights Reserved</p>
        <p class="footerP">湖北华中文化产权交易所有限公司 版权所有</p>
    </div>
</template>


<script>
export default {
    data(){
        return {
            hidden2flag : false,
        }
    },
    methods:{
        
        changejt(){
            var footerjt = document.getElementById("footerjt")
            var hiddenbox2 = document.getElementById("hiddenbox2")

            if(this.hidden2flag){
                hiddenbox2.style.height = "0"
                footerjt.style.transform = "rotateZ(0deg)"
                this.hidden2flag = false
            }else{
                hiddenbox2.style.height = "4rem"
                footerjt.style.transform = "rotateZ(-180deg)"
                this.hidden2flag = true
            }

        }
    },
    mounted() {

        // var footerjt = document.getElementById("footerjt")
        // var hiddenbox2 = document.getElementById("hiddenbox2")
        // var hidden2flag = false



        // footerjt.onclick = function(){
        //     if(hidden2flag){
        //         hiddenbox2.style.height = "0"
        //         footerjt.style.transform = "rotateZ(0deg)"
        //         hidden2flag = false
        //     }else{
        //         hiddenbox2.style.height = "4rem"
        //         footerjt.style.transform = "rotateZ(-180deg)"
        //         hidden2flag = true
        //     }
        // }
    }
}
</script>


<style lang="scss">


</style>