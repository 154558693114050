import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)
const routes = [
  {path:"/",redirect:"/sy"},
  {
    path:"/sy",
    name:"index",
    component:()=>import("@/views/index.vue"),
    meta: {
      keepAlive: true,    //开启缓存
      title: '文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
    },
  },
  {
    path:"/gywm",
    name:"gywm",
    component:()=>import("@/views/aboutus/aboutus.vue"),
    meta: {
      title: '关于我们-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
    }
  },
  {
    path:"/hydt",
    name:"hydt",
    redirect:"/hydt/yszx",
    component:()=>import("@/views/news/news.vue"),
    meta: {
      keepAlive: true,    //开启缓存
      meta: {
        title: '新闻资讯-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
      }
    },
    children:[
      {
        path:"yszx",
        name:"yszx",
        component:()=>import("@/views/news/yszx.vue"),
        meta: {
          keepAlive: true,    //开启缓存
          title: '影视资讯-新闻资讯-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
        },
      },
      {
        path:"hyfx",
        name:"hyfx",
        component:()=>import("@/views/news/hyfx.vue"),
        meta: {
          keepAlive: true,    //开启缓存
          title: '行业分析-新闻资讯-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
        },
      },
    ]
  },
  {
    path:"/zcfg",
    name:"zcfg",
    redirect:"/zcfg/flfg",
    component:()=>import("@/views/zcfg/zcfg.vue"),
    meta: {
      keepAlive: true,    //开启缓存
      meta: {
        title: '政策法规-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
      }
    },
    children:[
      {
        path:"flfg",
        name:"flfg",
        component:()=>import("@/views/zcfg/flfg.vue"),
        meta: {
          keepAlive: true,    //开启缓存
          title: '法律法规-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
        },
      },
      {
        path:"bmgz",
        name:"bmgz",
        component:()=>import("@/views/zcfg/bmgz.vue"),
        meta: {
          keepAlive: true,    //开启缓存
          title: '部门规章-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
        },
      },
      {
        path:"gfxwj",
        name:"gfxwj",
        redirect:"/zcfg/gfxwj/zhl",
        component:()=>import("@/views/zcfg/gfxwj.vue"),
        meta: {
          title: '规范性文件-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
        },
        children:[
          {
            path:"zhl",
            name:"zhl",
            component:()=>import("@/views/zcfg/gfxwj/zhl.vue"),
            meta: {
              title: '综合类-规范性文件-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
            },
          },
          {
            path:"dyzpsc",
            name:"dyzpsc",
            component:()=>import("@/views/zcfg/gfxwj/dyzpsc.vue"),
            meta: {
              title: '电影制片、审查-规范性文件-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
            },
          },
          {
            path:"dyfxfyjck",
            name:"dyfxfyjck",
            component:()=>import("@/views/zcfg/gfxwj/dyfxfyjck.vue"),
            meta: {
              title: '电影发行放映、进出口-规范性文件-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
            },
          },
          {
            path:"ncdyfygc",
            name:"ncdyfygc",
            component:()=>import("@/views/zcfg/gfxwj/ncdyfygc.vue"),
            meta: {
              title: '农村电影放映工程-规范性文件-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
            },
          },
          {
            path:"dyjjzc",
            name:"dyjjzc",
            component:()=>import("@/views/zcfg/gfxwj/dyjjzc.vue"),
            meta: {
              title: '电影经济政策-规范性文件-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
            },
          },
          {
            path:"dyjs",
            name:"dyjs",
            component:()=>import("@/views/zcfg/gfxwj/dyjs.vue"),
            meta: {
              title: '电影技术-规范性文件-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
            },
          },
          {
            path:"dyzxzj",
            name:"dyzxzj",
            component:()=>import("@/views/zcfg/gfxwj/dyzxzj.vue"),
            meta: {
              title: '电影专项资金-规范性文件-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
            },
          },
        ]
      },
      {
        path:"zcjd",
        name:"zcjd",
        component:()=>import("@/views/zcfg/zcjd.vue"),
        meta: {
          keepAlive: true,    //开启缓存
          title: '政策解读-规范性文件-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
        },
      },
      {
        path:"ptgz",
        name:"ptgz",
        component:()=>import("@/views/zcfg/ptgz.vue"),
        meta: {
          keepAlive: true,    //开启缓存
          title: '平台规则-规范性文件-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
        },
      },
    ]
  },
  {
    path:"/xmpl",
    name:"xmpl",
    redirect:"/xmpl/ysyz",
    component:()=>import("@/views/movie/movielist.vue"),
    meta: {
      keepAlive: true,    //开启缓存
      title: '项目披露-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
    },
    children:[
      {
        path:"ysyz",
        name:"ysyz",
        component:()=>import("@/views/movie/ysyz.vue"),
        meta: {
          keepAlive: true,    //开启缓存
          title: '影视预展-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
        },
      },
      {
        path:"yxzj",
        name:"yxzj",
        component:()=>import("@/views/movie/yxzj.vue"),
        meta: {
          keepAlive: true,    //开启缓存
          title: '意向征集-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
        },
      },
      {
        path:"xmgp",
        name:"xmgp",
        component:()=>import("@/views/movie/xmgp.vue"),
        meta: {
          keepAlive: true,    //开启缓存
          title: '项目挂牌-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
        },
      },
      {
        path:"cjgg",
        name:"cjgg",
        component:()=>import("@/views/movie/cjgg.vue"),
        meta: {
          keepAlive: true,    //开启缓存
          title: '成交公告-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
        },
      },
    ]
  },
  {
    path:"/bsxw",
    name:"bsxw",
    component:()=>import("@/views/bsxw/bsxw.vue"),
    meta: {
      keepAlive: true,    //开启缓存
      title: '本所新闻-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
    }
  },
  {
    path:"/hzjg",
    name:"hzjg",
    component:()=>import("@/views/hzjg/hzjg.vue"),
    meta: {
      keepAlive: true,    //开启缓存
      title: '合作机构-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
    }
  },
  {
    path:"/moviedetail/:movieId",
    name:"moviedetail",
    component:()=>import("@/views/movie/moviedetail.vue"),
    meta: {
      title: '文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
    }
  },
  {
    path:"/companydetail/:companyId",
    name:"companydetail",
    component:()=>import("@/views/movie/companydetail.vue"),
    meta: {
      title: '文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
    }
  },
  {
    path:"/newsdetail/:articleId",
    name:"newsdetail",
    component:()=>import("@/views/news/newsdetail.vue"),
    meta: {
      title: '文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
    }
  },
  // {path:"/gyhd",name:"gyhd",component:()=>import("@/views/gyhd/gyhd.vue")},
  // {
  //   path:"/userGuide",
  //   name:"userGuide",
  //   redirect:"/userGuide/profit",
  //   component:()=>import("@/views/userGuide/index.vue"),
  //   meta: {
  //     keepAlive: true    //开启缓存
  //   },
  //   children:[
  //     {
  //       path:"profit",
  //       name:"profit",
  //       component:()=>import("@/views/userGuide/profit.vue"),
  //       meta: {
  //         keepAlive: true    //开启缓存
  //       },
  //     },
  //     {
  //       path:"subscription",
  //       name:"subscription",
  //       component:()=>import("@/views/userGuide/subscription.vue"),
  //       meta: {
  //         keepAlive: true    //开启缓存
  //       },
  //     },
  //     {
  //       path:"qualifications",
  //       name:"qualifications",
  //       component:()=>import("@/views/userGuide/qualifications.vue"),
  //       meta: {
  //         keepAlive: true    //开启缓存
  //       },
  //     },
  //   ]
  // },
  {
    path:"/connectus",
    name:"connectus",
    component:()=>import("@/views/connectus/connectus.vue"),
    meta: {
      keepAlive: true,    //开启缓存
      title: '联系我们-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
    },
  },
  {
    path:"/jrpf",
    name:"jrpf",
    component:()=>import("@/views/jrpf/jrpf.vue"),
    meta: {
      title: '今日票房-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
    },
  },
  {
    path:"/yszb",
    name:"yszb",
    component:()=>import("@/views/yszb/yszb.vue"),
    meta: {
      keepAlive: true,    //开启缓存
      title: '影视周边-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
    },
  },


  {
    path:"/dl",
    name:"dl",
    component:()=>import("@/views/grzx/dl.vue"),
    meta: {
      title: '影视周边-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
    },
  },


  {
    path:"/grzx",
    name:"grzx",
    component:()=>import("@/views/grzx/grzx.vue"),
    meta: {
      keepAlive: true,    //开启缓存
      title: '个人中心-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
    },
  },


  {
    path:"/zkxm",
    name:"zkxm",
    component:()=>import("@/views/grzx/zkxm.vue"),
    meta: {
      keepAlive: true,    //开启缓存
      title: '收藏文章-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
    },
  },

  {
    path:"/wdsc",
    name:"wdsc",
    component:()=>import("@/views/grzx/wdsc.vue"),
    meta: {
      keepAlive: true,    //开启缓存
      title: '收藏项目-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
    },
  },


  {
    path:"/wdxm",
    name:"wdxm",
    component:()=>import("@/views/grzx/wdxm.vue"),
    meta: {
      keepAlive: true,    //开启缓存
      title: '我的项目-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
    },
  },

  {
    path:"/xmxq/:xmId",
    name:"xmxq",
    component:()=>import("@/views/grzx/xmxq.vue"),
    meta: {
      title: '我的项目-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
    }
  },

  {
    path:"/ptxx",
    name:"ptxx",
    component:()=>import("@/views/grzx/ptxx.vue"),
    meta: {
      title: '平台消息-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
    },
  },

  {
    path:"/xmrg/:xmId",
    name:"xmrg",
    component:()=>import("@/views/grzx/xmrg.vue"),
    meta: {
      title: '项目认购-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
    },
  },



  {
    path:"/404",
    name:"notFount",
    component:()=>import("@/views/errorPage/404.vue"),
    meta: {
      title: '文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
    }
  },
  {
    path:"*",
    redirect:{name:"notFount"},
    meta: {
      title: '文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台',
    }
  }
]


const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}


const router = new VueRouter({
  mode: 'history',
  routes
})



router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面meta */
  // console.log(to.params.articleTitle)
  // console.log(to.meta.content)
  if(to.meta.content){
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    if(to.params.articleTitle){
      document.querySelector('meta[name="keywords"]').setAttribute('content', `华中影视产权交易平台,电影投资,影视投资,影视产权,交易平台,华中文交所`)
      document.querySelector('meta[name="description"]').setAttribute('content', `华中影视产权交易平台属华中文交所旗下，通过技术链、产业链和价值链的搭建和完善，整合影视产业上下游资源，提供专业高效的综合配套服务，同时，根据影视业特点匹配金融支撑体系，为影视文化等泛娱乐产业的发展提供全方位投融资解决方案。`)
    }else if(to.params.movieName){
      document.querySelector('meta[name="keywords"]').setAttribute('content', `华中影视产权交易平台,电影投资,影视投资,影视产权,交易平台,华中文交所`)
      document.querySelector('meta[name="description"]').setAttribute('content', `华中影视产权交易平台属华中文交所旗下，通过技术链、产业链和价值链的搭建和完善，整合影视产业上下游资源，提供专业高效的综合配套服务，同时，根据影视业特点匹配金融支撑体系，为影视文化等泛娱乐产业的发展提供全方位投融资解决方案。`)
    }else{
      document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
      document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    }
    
    meta.content = to.meta.content;
    head[0].appendChild(meta)
  }
  // /* 路由发生变化修改页面title */
  if(to.params.articleTitle){
    document.title = `${to.params.articleTitle}-新闻资讯-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台`;
  }else if(to.params.movieName){
    document.title = `${to.params.movieName}-项目推荐-文化产业投资_影视投资【安全运作，专业智选】 -华中影视产权交易平台`;
  }else if(to.meta.meta){
    document.title = to.meta.meta.title;
  }else{
    document.title = to.meta.title;
  }
    
  next()
});



export default router
