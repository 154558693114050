import {axios,get,post} from "@/utils/axios"

export const getMovieProject = params => get("/jeecg-boot/front/movie/movieProject/list",params);

export const getListColumn = params => get("/jeecg-boot/front/movie/homepage/listColumn",params);
export const getChildColumn = params => get("/jeecg-boot/front/movie/homepage/listChildColumn",params);

export const getMovieArticle = params => get("/jeecg-boot/front/movie/movieArticle/list",params);

export const getMovieArticleById = params => get("/jeecg-boot/front/movie/movieArticle/queryById",params);

export const getArticleAndProject = params => get("/jeecg-boot/front/movie/homepage/listArticleAndProject",params);

export const getListBanner = params => get("/jeecg-boot/front/movie/homepage/listBanner",params);

export const getlistOnHomepage = params => get("/jeecg-boot/front/movie/movieProject/listOnHomepage",params);

export const getlistOnHomepage2 = params => get("/jeecg-boot/front/movie/movieArticle/listOnHomepage",params);

export const queryDetailByProjectId = params => get("/jeecg-boot/front/movie/movieProject/queryById",params);

//通过项目id查询物料列表
export const listMaterialByProjectId = params => get("/jeecg-boot/front/movie/movieProject/listMaterialByProjectId",params);

//通过项目id查询资质列表
export const listQualificationByProjectId = params => get("/jeecg-boot/front/movie/movieProject/listQualificationByProjectId",params);

//通过项目id查询公司列表
export const listCompanyByProjectId = params => get("/jeecg-boot/front/movie/movieCompany/listCompanyByProjectId",params);

//通过项目id查询影人列表
export const listCelebrityByProjectId = params => get("/jeecg-boot/front/movie/movieCelebrity/listCelebrityByProjectId",params);

//通过项目id查询进度列表
export const listScheduleByProjectId = params => get("/jeecg-boot/front/movie/movieProject/listScheduleByProjectId",params);

//通过项目id查询影片亮点
export const listHighlightsText = params => get("/jeecg-boot/front/movie/movieProject/queryDetailByProjectId",params);

//影人表-通过id查询
export const movieCelebrityQueryById = params => get("/jeecg-boot/front/movie/movieCelebrity/queryById",params);

//影人表-通过id查询
export const movieCompanyQueryById = params => get("/jeecg-boot/front/movie/movieCompany/queryById",params);

export const movieProjectList = params => get("/jeecg-boot/front/movie/movieProject/list",params);

export const addForm = params => post("/jeecg-boot/front/movie/movieForm/addForm",params);

//获取验证码
export const sms = params => post("/jeecg-boot/sys/customizeSms",params);

//登录注册
export const phoneRegister = params => post("/jeecg-boot/sys/customizePhoneLogin",params);

//新增摘牌订单表
export const addMovieDelistedOrder = params => post("/jeecg-boot/front/movie/movieDelistedOrder/add",params);

//摘牌订单-分页列表查询
export const listMovieDelistedOrder = params => post("/jeecg-boot/front/movie/movieDelistedOrder/list",params);


//摘牌订单-详情
export const getDelistedOrderDetail = params => post("/jeecg-boot/front/movie/movieDelistedOrder/getDelistedOrderDetail",params);


//公告列表
export const listNoticeOnHomepage = params => get("/jeecg-boot/front/movie/homepage/listNoticeOnHomepage",params);

//添加或更新用户关注
export const addOrUpdateUserInterest = params => post("/jeecg-boot/front/movie/movieUserInterest/addOrUpdateUserInterest",params);

//获取用户关注状态
export const getUserInterestResult = params => post("/jeecg-boot/front/movie/movieUserInterest/getUserInterestResult",params);

//关注的文章列表
export const listInterestArticle = params => post("/jeecg-boot/front/movie/movieUserInterest/listInterestArticle",params);

//关注的文章列表
export const listInterestProject = params => post("/jeecg-boot/front/movie/movieUserInterest/listInterestProject",params);





//分页查询电影
export const getMovie = params => post("/official/ow-movie/pageMovie",params);
  


//获取电影详情
export const getMovieDetail = params => post("/official/ow-movie/getMovieDetail",params);



//条件查询问答列表
export const listQa = params => post("/official/ow-question-answer/listQa",params);



//条件查询文章
export const listArticle = params => post("/official/ow-article/pageArticle",params);


//条件查询文章
export const getArticleDetail = params => post("/official/ow-article/getArticleDetail",params);


//条件查询电话客服
export const listPhoneStaff = params => post("/official/ow-phone-staff/listPhoneStaff",params);


//条件查询微信客服
export const listWechatStaff = params => post("/official/ow-wechat-staff/listWechatStaff",params);
  

//条件查询banner列表
export const listBanner = params => post("/api/owBanner/listBanner",params);
  

//条件查询人物列表
export const listPerson = params => post("/official/ow-person/listPerson",params);


//新增或者修改客户查询信息
export const saveOrUpdate = params => post("/inquiry/inquiry-customer/saveOrUpdate",params);


//发送验证码
export const sendCode = params => post("/sms/sms-customer/sendCode",params);


//发送验证码
export const loginBySms = params => post("/auth/loginBySms",params);


